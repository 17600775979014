import React, { useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import { useParams } from "react-router-dom";
import "../../Assets/Styles/Products.css";  // Utilisation des mêmes styles que EditProduct

function AddPromo() {
    const [error, setError] = useState("");
    const [promo, setPromo] = useState({
        // codePromo: "",
        // pourcentagePromo: "",
        validity: "",
        productPricePromo: "" ,
    });

    const { id } = useParams(); // Récupérer l'ID du produit

    // const { codePromo, pourcentagePromo,productPricePromo, validity } = promo;
    const { productPricePromo, validity } = promo;

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setPromo({ ...promo, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await Instance.patch(`${config.api_url}/product/add-promo/${id}`, promo);
            console.log(res);
            alert("Promo ajouter avec succés")
            window.location = "/dashboard/product"; // Redirection après ajout réussi
        } catch (error) {
            console.error("-+-+-+-Erreur lors de l'ajout de la promotion:", error);
            setError(error);
        }
    };

    

    return (
        <div className="a-new-product">
            <SideBar />
            <div className="parent-new-product">
                <form onSubmit={handleSubmit} className="new-product custom-layout-product">
                    <div className="form-display">
                        {/* <div>
                            <label htmlFor="codePromo">Code Promo</label>
                            <input
                                className="input"
                                type="text"
                                value={codePromo}
                                name="codePromo"
                                onChange={onInputChange}
                                required
                            />
                        </div> */}
                        <div>
                            <label htmlFor="productPricePromo">Prix du Produit en Promo </label>
                            <input
                                className="input"
                                type="number"
                                value={productPricePromo}
                                name="productPricePromo"
                                onChange={onInputChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="validity">Validité (en jours)</label>
                            <input
                                className="input"
                                type="date"
                                value={validity}
                                name="validity"
                                onChange={onInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="validate">
                        <button type="submit" className="add-new-element">Ajouter Promotion</button>
                    </div>
                </form>
            </div>
            {error && (
                <h1 style={{ color: "red", position: "fixed", left: "10%", bottom: "10%" }}>
                    {error.response?.data?.message || "Erreur lors de l'ajout de la promotion"}
                </h1>
            )}
        </div>
    );
}

export default AddPromo;
