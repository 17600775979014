import React, { useContext, useState, useEffect } from 'react';
import Instance from '../../Services/Instance';
import { DataContext } from '../../Services/DataContext';
import config from '../../Services/config.js';
import logo from '../../Assets/Imgs/senpremiumlogo2 3.svg';
import home from '../../Assets/Imgs/home6.png';
import google from '../../Assets/Imgs/icon_google.png';
import '../../Assets/Styles/Connection.css';

const SignIn = () => {
    const [data, setData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const [err, setErr] = useState('');

    const { toggleBooleanValue } = useContext(DataContext);

    // Vérifie l'expiration du token lors du chargement initial du composant
    useEffect(() => {
        checkTokenExpiration();
    }, []);

    // Fonction pour vérifier l'expiration du token
    const checkTokenExpiration = () => {
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        if (tokenExpiration) {
            const expirationTime = parseInt(tokenExpiration);
            const currentTime = new Date().getTime();
            if (currentTime > expirationTime) {
                // Supprimer le token et déconnecter l'utilisateur
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                localStorage.removeItem('user');
            }
        }
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.api_url}/login`;
            const { data: response } = await Instance.post(url, data);

            const user = response.user;
            // if (user && user.role === 0) {
            //     setErr('Seuls les admins sont autorisés à se connecter');

            // } 
                toggleBooleanValue();
                window.location = '/dashboard';
                localStorage.setItem('token', response.token);
                localStorage.setItem('user', JSON.stringify(user));
                // Stocker l'heure d'expiration du token
                const expiresIn = 86400 * 7; // 30 jour en secondes
                const expirationTime = new Date().getTime() + expiresIn * 1000;
                localStorage.setItem('tokenExpiration', expirationTime);
        } catch (error) {
            console.error('------Erreur lors de la connexion:', error);
            setError(error);
        }
    };

    return (
        <div className='layout-signin'>
            <div className='logo'>
                <img src={logo} alt='logo' />
            </div>
            <div className='form-signin'>
                <form onSubmit={handleSubmit}>
                    <div className='card-title-signin title-google '>
                        <img src={google} alt='google' />
                        <h3> Se Connecter avec Google</h3>
                    </div>
                    <div className='parent-form-input'>
                        <label htmlFor='email'>Email:</label>
                        <input
                            className='input'
                            type='email'
                            name='email'
                            id='email'
                            value={data.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='parent-form-input'>
                        <label htmlFor='password'>Mot de passe:</label>
                        <input
                            className='input'
                            type='password'
                            name='password'
                            id='password'
                            value={data.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {error && <h1 style={{ color: 'red' }}>{error.response.data.message}</h1>}
                    <button type='submit' className='btn-form-connection' id='btn-signin'>
                        Se connecter
                    </button>
                </form>
                <div className='container-right-signin'>
                    <div className='card-title-signin '>
                        <h3> Ak SenPremium</h3>
                    </div>
                    <img src={home} alt='img-signin' />
                </div>
            </div>
            <div>
                {err && <h1 style={{ color: 'red' }}>{err}</h1>}
            </div>
        </div>
    );
};

export default SignIn;
