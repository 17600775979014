import React, { useEffect, useState } from "react";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import SideBar from "../../Layouts/SideBar";
import "../../Assets/Styles/Products.css";
import { useParams } from "react-router-dom";
import {Link} from "react-router-dom"

function ShowProduct() {
    const [error, setError] = useState("");
    const [product, setProduct] = useState([]);
    const { id } = useParams();
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(
                    `${config.api_url}/show-product/${id}`
                );
                setProduct(result.data.product);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        const defaultFaqs = product.faq || [];
        setFaqs(defaultFaqs.map(faq => ({ ...faq, statusFaq: false })));
    }, [product]);

    const formattedDate = (_date) => {
        const dateObject = new Date(_date);
        return dateObject.toLocaleDateString('fr-FR');
    };

    const toggleFaq = (index) => {
        setFaqs(prevFaqs => prevFaqs.map((faq, i) => {
            if (i === index) {
                return { ...faq, statusFaq: !faq.statusFaq };
            } else {
                return faq;
            }
        }));
    };
    
    return (
        <div className="a-new-product">
            <SideBar />
            <div className='show-elements-details'>
                <div>
                    <button> </button>
                </div>
                <img src={`${config.img_url}/${product.productImage}`} alt="img-product"  className="img-product"/>
                {product.promo && product.promo.InPromo === true && <p>Promo : <span> Produit en Promo  </span></p>}
                {/* {product.promo && product.promo.InPromo === true && <p>Code Promo : <span> {product.promo.codePromo} </span></p>} */}
                {product.promo && product.promo.InPromo === true && <p>Prix Produit en Promo : <span> {product.promo.productPricePromo} FCFA</span></p>}
                {product.promo && product.promo.InPromo === true && <p>Fin de  Promo : <span>{typeof(product.promo.validity) === Number ? product.promo.validity : formattedDate(product.promo.validity)  }  </span></p>}
                {!product.promo || product.promo.InPromo === false && <p>Promo : <span> Non en Promo  </span></p>}
                <p>Prix : <span> {product.productPrice} FCFA </span></p>
                <p>Date de création : <span> {formattedDate(product.createdAt)} </span></p>
                <p>Produit  : <span> {product.productName} </span></p>
                <p>Catégorie : <span> {product.productCategory} </span></p>
                <p>Type  : <span> {product.productType} </span></p>
                <p>Description  : <span> {product.productDescription} </span></p>
            
                <h1 className='faq-title'>FAQ</h1>
                <div className='faq-content'>
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <div className='faq-item'>
                                <p>{faq.question}</p>
                                {faq.statusFaq ? (
                                    <h1 onClick={() => toggleFaq(index)} className='chevron arrow-faq' > ⌵ </h1>
                                ) : (
                                    <h1 onClick={() => toggleFaq(index)} className='arrow-faq' > ⌵ </h1>
                                )}
                            </div>
                    
                            {faq.statusFaq && (
                                <p className='faq-description'>{faq.answer}</p>
                            )}
                            <hr />
                        </div>
                    ))}
                </div>
                <div className="validate">
                    <Link to={`/dashboard/product/add-promo/${product._id}`} className="add-Promo add-new-element">Ajoutez un Promo</Link>
                </div>
            </div>
            {error && (
                <h1
                    style={{
                        color: "red",
                        position: "fixed",
                        left: "10%",
                        bottom: "10%",
                    }}
                >
                    {error.response.data.message}
                </h1>
            )}
        </div>
    );
}

export default ShowProduct;
